import styled from 'styled-components'
import media from '../../styles/media'
import theme from '../../styles/theme'

export const InvoiceDataSection = styled.div`
  display: flex;

  .no-side-margins {
    margin-left: 0;
    margin-right: 0;
    &.top-margin {
      margin-top: 2rem;
    }
  }

  .box {
    margin-top: 2rem;
    padding: 1rem 0;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
    background-color: #ffffff;
  }

  .purchases-container {
    height: max-content;
    margin-right: 1rem;
    padding: 1rem 3rem;

    .rejected-message {
      color: #d15858;
      margin-left: 15px;
      font-size: 16px;
      font-family: Roboto;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
    }

    .lbl {
      height: 20px;
      font-family: Roboto;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #000000;
    }

    .input-container {
      flex: 2;
    }

    .input-st {
      margin-top: 0.5rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      height: 40px;
      border-radius: 2px;
      border: solid 1px #d9d9d9;
      min-width: 350px;
      &:focus {
        border-color: #c59d5f;
        outline: none;
        box-shadow: 0px 0px 2px 0px #c59d5f;
      }
      &::placeholder {
        color: #cbcbcb;
      }
      &.error {
        border-color: red;
      }
    }

    .obligatory-txt {
      height: 20px;
      font-family: Roboto;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      color: #000000;
      padding: 2rem;
      .asterisk {
        color: red;
        padding-right: 0.3rem;
      }
    }

    .select-control-container {
      margin-top: 0.5rem;

      .select-control__control {
        border-radius: 2px;
        height: 40px;

        &.select-control__control--is-focused {
          border: solid 1px ${theme.veryLightPink};
          box-shadow: none;
        }
      }

      .select-control__menu {
        color: #000000;
        margin-top: 0;
      }

      .select-control__indicators {
        div {
          color: ${theme.blue};
        }

        span {
          background-color: transparent;
        }
      }

      &.error {
        .select-control__control {
          border: solid 1px red;
        }
      }
    }
  }

  .totals {
    height: 100%;
    width: 100%;
    margin-left: 1rem;

    .total-row {
      display: flex;

      &.bottom-padding {
        padding-bottom: 1rem;
      }
    }

    .next-btn {
      margin: 1rem auto;
      display: flex;
      justify-content: center;
    }

    .p2p-info {
      margin: 1rem auto;
      display: flex;
      justify-content: center;
      font-family: Roboto;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #75787b;
      img {
        width: 76px;
      }
    }
  }

  .title {
    height: 20px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #000000;
    text-transform: uppercase;
    &.colored {
      color: #c59d5f;
    }

    &.col {
      padding: 0 1rem 0 2rem;
    }

    &.row {
      margin: 0.5rem 1.5rem 1rem;
    }

    &.first {
      padding-left: 1rem;
    }
  }

  .item-prop {
    width: 119px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #000000;

    &.col {
      padding-top: 1rem;
      padding-left: 1.5rem;
      &.first-row {
        padding-top: 1rem;
      }
      &.second-row {
        padding-top: 0;
      }
    }

    &.sub-value {
      padding-top: 1rem;
      padding-right: 1.5rem;
      text-align: right;
      font-weight: bold;
      line-height: normal;
      letter-spacing: 1.08px;
    }

    &.row {
      margin-top: 1.5rem;
      margin-left: auto;
      margin-right: 1.5rem;
    }
  }

  .total {
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    &.colored {
      color: #c59d5f;
    }

    &.label {
      line-height: 1.11;
      letter-spacing: normal;
    }
    &.value {
      line-height: normal;
      letter-spacing: 1.38px;
    }
  }

  .terms-conditions {
    margin: 2rem 1.5rem;

    .styled-chk {
      margin-bottom: 0;
      position: relative;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        &:checked ~ .checkmark {
          background-color: #c59d5f;
        }
        &:checked ~ .checkmark:after {
          display: block;
        }
      }

      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: white;
        border: solid 2px #000000;
        &:after {
          content: '';
          position: absolute;
          display: none;
          left: 7px;
          top: 3px;
          width: 5px;
          height: 10px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }

      &.error {
        .checkmark {
          border-color: red;
        }
      }
    }

    .checkbox-lbl {
      padding-left: 2rem;
      color: #353535;
      a {
        padding-left: 0.3rem;
        color: #c59d5f;
        text-decoration: underline;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  ${media.sm`
    .purchases-container {
      min-width: 60%;
      padding: 1rem;
      .top-margin {
        margin-top: 0;
        .col {
          margin: 1rem auto;
        }
      }
    }

    .totals {
      .p2p-info {
        margin-left: 0;
        img {
          margin-bottom: 0;
        }
      }
    }
  `}

  ${media.md`
    .box {
      margin-top: 3rem;
    }

    .purchases-container {
      min-width: 650px;
      .col {
        .input-st {
          width: 100%;
        }
      }
    }
  `}

  ${media.lg`
    .purchases-container {
      min-width: 850px;
      .col {
        .input-st {
          width: 100%;
          &.full-width {
            width: 100%;
          }
        }
        .email-lbl-err {
          width: 48%;
        }
      }
    }
    .totals {
      .p2p-info {
        margin-left: -0.5rem;
      }
    }
  `}

  @media screen and (max-width: 767px) {
    flex-direction: column;

    .box {
      padding: 0 0.5rem;
    }

    .purchases-container {
      min-width: 100%;
      padding-top: 1rem;
      .top-margin {
        margin-top: 0;
      }
      .col {
        padding-bottom: 1rem;
        min-width: 100%;
      }
      .input-st {
        min-width: 100%;
      }
      .select-control-container {
        width: 100%;
      }
    }

    .title {
      &.row {
        margin-top: 1.5rem;
      }
    }

    .totals {
      margin-left: 0;
      .box {
        padding-bottom: 1rem;
      }
      .p2p-info {
        img {
          margin-bottom: 0;
        }
      }
    }
  }
`

export const PaymentButton = styled.button`
  outline: none;
  background: ${theme.secondaryColor};
  border: solid 1px ${theme.secondaryColor};
  padding: 0.5rem 1rem;
  color: white;
  border-radius: 22.5px;
  letter-spacing: 1.7px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  width: 70%;
  height: 45px;
  ${media.sm`
  width: 90%;
  `}

  .btn-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    img {
      margin-right: 0.5rem;
    }

    .payment-btn-text {
      font-size: 11.5px;
      display: flex;
      flex-direction: column;
      text-align: left;
      justify-content: center;
      line-height: 1.3;
      width: 50%;
      ${media.sm`
      width: 40%;
      font-size: 10px;
      `}
      ${media.md`
      width: 50%;
      font-size: 12px;
      `}

      &.long {
        ${media.sm`
        width: 45%;
        `}
        ${media.md`
        width: 55%;
        `}
      }
    }
  }

  &:hover {
    background: #ffffff;
    color: ${theme.secondaryColor};
    img {
      filter: invert(30%) sepia(60%) saturate(1200%) hue-rotate(331deg) brightness(87%)
        contrast(85%);
    }
  }

  &.diners {
    background: ${theme.purpleBrown};
    border-color: ${theme.purpleBrown};
    &:hover {
      background: #ffffff;
      border-color: ${theme.purpleBrown};
      color: ${theme.purpleBrown};
      img {
        filter: invert(100%) sepia(71%) saturate(2600%) hue-rotate(9deg) brightness(10%)
          contrast(20%);
      }
    }
  }
`
