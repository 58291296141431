import React from 'react'
import InvoiceData from '../components/InvoiceData'
import Layout from '../components/Layout'

export default () => (
  <Layout themeType="dark" currentPage={'invoice-data'}>
    <div>
      <InvoiceData />
    </div>
  </Layout>
)
