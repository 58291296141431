import { navigate } from '@reach/router'
import axios from 'axios'
import { withPrefix } from 'gatsby'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify'
import AuthContext from '../../context/auth-context'
import { GtmEventsLoad } from '../../helpers/gtm'
import { EventAction } from '../../helpers/GTM/EventAction'
import { RequestGetter } from '../../helpers/request'
import { validateIdentification } from '../../utils/validateIdentification'
import withLocation from '../AuthSection/withLocation'
import { StyledCheckBox, StyledError } from '../Collapse/stepped/style'
import PurchaseLayout from '../Layout/Purchase'
import Loading from '../Loading'
import { P2PLogo } from '../MembershipForm/style'
import { InvoiceDataSection, PaymentButton } from './style'

declare const P: any
const dinersLogo = require('../../assets/images/pages/cart/diners-logo.svg')
const grandeTableLogo = require('../../assets/images/pages/cart/logo.svg')

class InvoiceData extends Component<{}, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      fields: {},
      errors: {},
      terms: false,
      errorTerms: null,
      loading: true,
      requestId: 0,
      viewP2P_message: false,
      once: 0,
      identificationTypes: [
        { value: 'C', label: 'Cédula' },
        { value: 'R', label: 'RUC' },
        { value: 'P', label: 'Pasaporte' },
      ],
      inputLenght: 0,
    }
  }

  componentDidMount() {
    const { purchase, getUser } = this.context
    const { search } = this.props
    const customer = getUser()

    if (!purchase) {
      navigate(withPrefix('/events'))
      return
    }

    if (search && search.reference) {
      this.retrieveInvoiceData(search.reference).then(() =>
        this.retrieveP2PInfo(this.state.requestId).then((p2pInfo: any) =>
          this.processTransaction(purchase.id, p2pInfo.state, p2pInfo.message, p2pInfo.reason).then(() => {
            if (this.shouldNavigate(p2pInfo)) {
              navigate(withPrefix('/payment-confirmation'))
              return
            }
            this.setState({ viewP2P_message: true })
          })
        )
      )
      return
    } else {
      if (customer) {
        this.validatePayment(customer.customerId)
      }
    }

    window.addEventListener(
      'message',
      (e) => {
        if (this.isJsonValid(e.data)) {
          this.handleResponse(e)
        }
      },
      false
    )

    if (purchase.transaction && purchase.transaction.status == 'APPROVED') {
      navigate(withPrefix('/payment-confirmation'))
    }
    this.setState({ loading: false })
  }

  isJsonValid = (jsonString: string): boolean => {
    try {
      JSON.parse(jsonString)
    } catch (e) {
      return false
    }
    return true
  }

  shouldNavigate(p2pInfo: any) {
    const rejectedReasons = ['?C', 'EX', '05']
    return (p2pInfo.state == 'REJECTED' && !rejectedReasons.includes(p2pInfo.reason)) || p2pInfo.state == 'APPROVED' || (p2pInfo.state == 'PENDING' && p2pInfo.reason)
  }

  retrieveInvoiceData = (transactionId: number) => {
    const { fields } = this.state
    return axios
      .post(`${process.env.API_URL}purchase/invoice-data`, { transactionId }, {})
      .then((data: any) => data.data)
      .then((response: any) => {
        fields['firstName'] = response.invoice_name
        fields['lastName'] = response.invoice_last_name
        fields['identificationType'] = this.retrieveIdentificationType(response.invoice_identification_type)
        fields['identification'] = response.invoice_idn
        fields['phone'] = response.invoice_phone
        fields['address'] = response.address
        fields['email'] = response.invoice_email
        this.setState({ fields, requestId: response.p2p_request_id })
      })
  }

  retrieveIdentificationType = (identificationType: string) => {
    switch (identificationType) {
      case 'C':
        return { value: 'C', label: 'Cédula' }
      case 'R':
        return { value: 'R', label: 'RUC' }
      case 'P':
        return { value: 'P', label: 'Pasaporte' }
      default:
        return { value: null }
    }
  }

  validatePayment = (id: any) => {
    axios
      .post(`${process.env.API_URL}purchase/getpendings`, { id }, {})
      .then((data: any) => {
        if (!data.data.valid) {
          navigate(withPrefix('/purchase-not-allowed'))
        }
      })
      .catch((error: any) => {
        console.log(error)
      })
  }

  notify = (message: string, type?: string) => {
    if (type === 'error') {
      return toast.error(message)
    }
    return toast(message)
  }

  handleUpdate = (field: string, event: any) => {
    let fields = this.state.fields
    fields[field] = event.target.value
    this.setState({ fields })
  }

  handleSelect = (identificationTypeSelected: { value: string; label: string }) => {
    let fields = this.state.fields
    fields['identificationType'] = identificationTypeSelected
    fields['identification'] = null
    fields['firstName'] = null
    fields['lastName'] = null
    this.setState({ fields, inputLenght: this.setInputLenght(identificationTypeSelected.value) })
  }

  handleIdentification = (event: React.ChangeEvent<HTMLInputElement>) => {
    let fields = this.state.fields
    const value = fields['identificationType'].value === 'P' ? event.target.value : event.target.value.replace(/[^0-9]/g, '')
    fields['identification'] = value
    this.setState({ fields })
  }

  setInputLenght = (identificationType: string) => {
    switch (identificationType) {
      case 'C':
        return 10
      case 'R':
        return 13
      case 'P':
        return 15
    }
  }

  handleValidation = () => {
    const { fields } = this.state
    let errors: any = {}
    let formIsValid = true
    if (!fields['firstName']) {
      formIsValid = false
      errors['firstName'] = 'El nombre es requerido.'
    }
    if (fields['identificationType'] && fields['identificationType'].value !== 'R' && /[^ñáéíóúa-z _]/i.test(fields['firstName'])) {
      formIsValid = false
      errors['firstName'] = 'El nombre solo debe contener letras.'
    }
    if (fields['identificationType'] && fields['identificationType'].value !== 'R' && !fields['lastName']) {
      formIsValid = false
      errors['lastName'] = 'El apellido es requerido.'
    }
    if (/[^ñáéíóúa-z _]/i.test(fields['lastName'])) {
      formIsValid = false
      errors['lastName'] = 'El apellido solo debe contener letras.'
    }
    if (!fields['identificationType']) {
      formIsValid = false
      errors['identificationType'] = 'El tipo de identificación es requerido.'
    }
    if (!fields['identification']) {
      formIsValid = false
      errors['identification'] = 'La identificación es requerida.'
    }
    if (fields['identification'] && fields['identificationType']) {
      const validIdentification = validateIdentification(fields['identification'], fields['identificationType'].value)
      if (validIdentification.error) {
        errors['identification'] = validIdentification.message
        formIsValid = false
      }
    }
    if (!fields['phone']) {
      formIsValid = false
      errors['phone'] = 'El teléfono es requerido.'
    }
    if (!fields['address']) {
      formIsValid = false
      errors['address'] = 'La dirección es requerida.'
    }
    if (!fields['email']) {
      formIsValid = false
      errors['email'] = 'El correo electrónico es requerido.'
    }
    if (!/\S+@\S+\.\S+/.test(fields['email'])) {
      formIsValid = false
      errors['email'] = 'El correo electrónico es incorrecto.'
    }
    if (this.state.terms === false) {
      formIsValid = false
      this.setState({ errorTerms: 'Aceptar los términos y condiciones.' })
    }
    this.setState({ errors })
    return formIsValid
  }

  handleResponse = (p2pResponse: any) => {
    const { purchase } = this.context

    if (p2pResponse.data && !p2pResponse.data.source) {
      this.setState({ loading: true })
      const p2pObject = JSON.parse(p2pResponse.data)
      const p2pData = p2pObject.status
      const p2pStatus = p2pData ? p2pData.status : ''
      switch (p2pStatus) {
        case 'REJECTED':
          this.retrieveP2PInfo(p2pObject.requestId).then((p2pInfo) =>
            this.processTransaction(purchase.id, p2pInfo.state, p2pInfo.message, p2pInfo.reason).then(() => {
              GtmEventsLoad(new EventAction('rejected', 'purchase', 'event').initialize({ eventData: purchase, quantity: purchase.items.length, purchaseData: purchase }))
              this.setState({ viewP2P_message: true })
            })
          )
          return
        case 'APPROVED':
        case 'PENDING':
          if (this.state.once === 0) {
            this.setState({ once: 1 })
            this.processTransaction(purchase.id, p2pStatus, p2pData.message, p2pData.reason).then(() => {
              this.setState({ viewP2P_message: false })
              navigate(withPrefix('/payment-confirmation'))
            })
          }
          return
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleResponse)
  }

  retrieveP2PInfo = async (requestId: number) => {
    const { purchase } = this.context

    return await axios
      .post(
        `${process.env.API_URL}purchase/p2p-info`,
        {
          requestId,
          purchaseId: purchase.id,
        },
        {}
      )
      .then((data: any) => data.data)
      .then((response: any) => {
        let p2pInfo: any = {}
        if (response.payment == null) {
          p2pInfo.state = response.status.status
          p2pInfo.message = response.status.message
          p2pInfo.reason = response.status.reason
          return p2pInfo
        }

        p2pInfo.state = response.payment[0].status.status
        p2pInfo.message = response.payment[0].status.message
        p2pInfo.reason = response.payment[0].status.reason
        return p2pInfo
      })
  }

  requestPayment(withGrandeTable: boolean) {
    const { fields } = this.state
    const { purchase, setPurchase } = this.context

    RequestGetter('POST', 'purchase/request', {
      id: purchase.id,
      facName: fields['firstName'],
      facLastname: fields['lastName'],
      identificationType: fields['identificationType'].value,
      facIdn: fields['identification'],
      facPhone: fields['phone'],
      facEmail: fields['email'],
      facAddress: fields['address'],
      withGrandeTable,
    })
      .then((data: any) => {
        P.init(data.data.processUrl, {
          opacity: 0.4,
        })
        this.processTransaction(purchase.id, 'pending', '', '').then(() => setPurchase({ ...purchase, purchased_wit_gt: withGrandeTable }))
      })
      .catch((error: any) => {
        if (error.response && error.response.status) {
          switch (error.response.status) {
            case 422:
              const { data } = error.response
              const errors = Object.keys(data)
              let response: any = []
              for (const index in data) {
                for (let i = 0; i < data[index].length; i++) {
                  response[`error${index.charAt(0).toUpperCase() + index.slice(1)}`] = data[index][i]
                }
              }
              this.setState(response)
              break
            default:
              const message = error.response.data.message ? error.response.data.message : 'Hubo un problema en la transacción'
              this.notify(message)
              break
          }
        }
      })

    this.generateEventForGTM(fields)
  }

  generateEventForGTM = (fields: any) => {
    const { purchase } = this.context
    GtmEventsLoad(
      new EventAction('checkout', 'checkout', 'event').initialize({ eventData: purchase, quantity: purchase.items.length, customerData: fields, purchaseData: purchase })
    )
  }

  obtainEventsAndTickets = (): string => {
    const { purchase } = this.context
    let eventsTickets = purchase.items
      .map((item: any) => {
        return `[${item.description}:${item.quantity}]`
      })
      .join('_')
    return eventsTickets
  }

  processTransaction = async (purchaseId: number, status: string, message: string, reason: string) => {
    const { purchase, setPurchase } = this.context
    this.setState({ loading: true })
    return await RequestGetter('POST', 'transactions/process', {
      id: purchaseId,
      status,
      message,
      reason,
    }).then((data: any) => {
      const invoiceData = data.data

      this.setState(
        {
          loading: false,
        },
        () =>
          setPurchase({
            ...purchase,
            active: invoiceData.active,
            transaction: invoiceData.transaction,
            invoice_id: invoiceData.invoice_id,
            status: invoiceData.status,
          })
      )
    })
  }

  formattedNumber = (numberValue: any, style: string) => {
    return <div className={style}>$ {numberValue ? Number(numberValue).toFixed(2).replace('.', ',') : '0,00'}</div>
  }

  handleSubmit = (event: any) => {
    event.preventDefault()
    if (this.handleValidation()) {
      this.requestPayment(false)
    }
  }

  handleGrandeTablePayment = (event: any) => {
    event.preventDefault()
    if (this.handleValidation()) {
      this.requestPayment(true)
    }
  }

  render() {
    const { purchase } = this.context
    const { errors, loading, fields, viewP2P_message, identificationTypes, inputLenght } = this.state
    return loading === true ? (
      <Loading />
    ) : (
      <PurchaseLayout title="Datos de facturación">
        <form onSubmit={this.handleSubmit.bind(this)}>
          <InvoiceDataSection>
            <div className="purchases-container box">
              {viewP2P_message ? (
                <div id="GTABLE_EVENTPAY_REJECTED" className="row rejected-message no-side-margins">
                  Tu transacción No: {purchase.transaction.id} ha sido rechazada.
                </div>
              ) : (
                ''
              )}
              <div className="row no-side-margins top-margin">
                <div className="col">
                  <div className="lbl">Tipo de identificación</div>
                  <Select
                    isSearchable={false}
                    name="identificationType"
                    className={`select-control-container ${errors['identificationType'] ? 'error' : ''}`}
                    classNamePrefix="select-control"
                    onChange={(currentType) => this.handleSelect(currentType)}
                    error={errors['identificationType']}
                    value={fields['identificationType'] || ''}
                    options={identificationTypes}
                    placeholder="Selecciona el tipo de identificación"
                  />
                  {errors['identificationType'] && <StyledError>{errors['identificationType']}</StyledError>}
                </div>
                <div className="col">
                  <div className="lbl">Identificación</div>
                  <input
                    name="identification"
                    value={fields['identification'] || ''}
                    className={`input-st ${errors['identification'] ? 'error' : ''}`}
                    type="text"
                    onChange={this.handleIdentification}
                    disabled={inputLenght === 0}
                    maxLength={inputLenght}
                  />
                  {errors['identification'] && <StyledError>{errors['identification']}</StyledError>}
                </div>
              </div>
              <div className="row no-side-margins top-margin">
                <div className="col">
                  <div className="lbl">
                    Nombre
                    {fields['identificationType'] && fields['identificationType'].value === 'R' ? ' Comercial' : ''}
                  </div>
                  <input
                    name="firstName"
                    value={fields['firstName'] || ''}
                    className={`input-st ${errors['firstName'] ? 'error' : ''}`}
                    type="text"
                    onChange={this.handleUpdate.bind(this, 'firstName')}
                    disabled={!fields['identificationType']}
                  />
                  {errors['firstName'] && <StyledError>{errors['firstName']}</StyledError>}
                </div>
                {fields['identificationType'] && fields['identificationType'].value !== 'R' && (
                  <div className="col">
                    <div className="lbl">Apellido</div>
                    <input
                      name="lastName"
                      value={fields['lastName'] || ''}
                      className={`input-st ${errors['lastName'] ? 'error' : ''}`}
                      type="text"
                      onChange={this.handleUpdate.bind(this, 'lastName')}
                    />
                    {errors['lastName'] && <StyledError>{errors['lastName']}</StyledError>}
                  </div>
                )}
              </div>
              <div className="row no-side-margins top-margin">
                <div className="col input-container">
                  <div className="lbl">Dirección</div>
                  <input
                    name="address"
                    value={fields['address'] || ''}
                    className={`input-st full-width ${errors['address'] ? 'error' : ''}`}
                    type="text"
                    onChange={this.handleUpdate.bind(this, 'address')}
                    disabled={!fields['identificationType']}
                  />
                  {errors['address'] && <StyledError>{errors['address']}</StyledError>}
                </div>
              </div>
              <div className="row no-side-margins top-margin">
                <div className="col">
                  <div className="lbl">Correo electrónico</div>
                  <input
                    name="email"
                    value={fields['email'] || ''}
                    className={`input-st ${errors['email'] ? 'error' : ''}`}
                    type="text"
                    onChange={this.handleUpdate.bind(this, 'email')}
                    disabled={!fields['identificationType']}
                  />
                  {errors['email'] && <StyledError className="email-lbl-err">{errors['email']}</StyledError>}
                </div>
                <div className="col">
                  <div className="lbl">Teléfono de contacto</div>
                  <input
                    name="phone"
                    value={fields['phone'] || ''}
                    className={`input-st ${errors['phone'] ? 'error' : ''}`}
                    type="text"
                    onChange={this.handleUpdate.bind(this, 'phone')}
                    disabled={!fields['identificationType']}
                  />
                  {errors['phone'] && <StyledError>{errors['phone']}</StyledError>}
                </div>
              </div>
              <div className="row obligatory-txt">
                <div className="asterisk">*</div>Todos los campos son obligatorios
              </div>
            </div>

            <div className="totals">
              <div className="box">
                <div className="total-row">
                  <div className="title row">TOTAL CON DINERS CLUB</div>
                </div>
                <hr style={{ color: '#dfdfdf', margin: '0 1.5rem' }} />
                <div className="total-row">
                  <div className="item-prop col first-row">SUBTOTAL</div>
                  {this.formattedNumber(purchase.subtotal, 'item-prop col first-row sub-value')}
                </div>
                <div className="total-row bottom-padding">
                  <div className="item-prop col second-row">IVA</div>
                  {this.formattedNumber(purchase.tax, 'item-prop col second-row sub-value')}
                </div>
                <hr style={{ color: '#dfdfdf', margin: '0 1.5rem' }} />
                <div className="total-row">
                  <div className="title item-prop col total label">TOTAL</div>
                  {this.formattedNumber(purchase.total, 'item-prop col total value sub-value')}
                </div>
              </div>
              <div className="box">
                <div className="total-row">
                  <div className="title row colored">TOTAL CON GRANDE TABLE</div>
                </div>
                <hr style={{ color: '#dfdfdf', margin: '0 1.5rem' }} />

                <div className="total-row">
                  <div className="item-prop col first-row">SUBTOTAL</div>
                  {this.formattedNumber(purchase.grande_table_subtotal, 'item-prop col first-row sub-value')}
                </div>
                <div className="total-row bottom-padding">
                  <div className="item-prop col second-row">IVA</div>
                  {this.formattedNumber(purchase.grande_table_tax, 'item-prop col second-row sub-value')}
                </div>
                <hr style={{ color: '#dfdfdf', margin: '0 1.5rem' }} />
                <div className="total-row">
                  <div className="title item-prop col total label colored">TOTAL</div>
                  {this.formattedNumber(purchase.grande_table_total, 'item-prop col total value sub-value colored')}
                </div>
              </div>

              <div className="row terms-conditions">
                <StyledCheckBox className="styled-chk" error={this.state.errorTerms !== null}>
                  Acepto{' '}
                  <a href="https://medias.grandetable.com.ec/docs/tyc_grande_table.pdf" target="_blank" style={{ color: '#c59d5f', textDecoration: 'underline' }}>
                    términos y Condiciones
                  </a>{' '}
                  <input type="checkbox" checked={this.state.terms} onChange={() => this.setState({ terms: !this.state.terms })} />
                  <span className="checkmark" />
                </StyledCheckBox>
              </div>

              <div className="next-btn">
                <PaymentButton id="GTABLE_PAY_DC" type="submit" className="diners">
                  <div className="btn-content">
                    <img src={dinersLogo} />
                    <span className="payment-btn-text">Pagar con DINERS CLUB</span>
                  </div>
                </PaymentButton>
              </div>
              <div className="next-btn">
                <PaymentButton id="GTABLE_PAY_GTABLE" onClick={this.handleGrandeTablePayment.bind(this)}>
                  <div className="btn-content">
                    <img src={grandeTableLogo} />
                    <span className="payment-btn-text long">Pagar con GRANDE TABLE</span>
                  </div>
                </PaymentButton>
              </div>
              <div className="p2p-info">
                Esta transacción se hará por:
                <P2PLogo src="https://static.placetopay.com/redirect/images/providers/placetopay.svg" alt="" />
              </div>
            </div>
          </InvoiceDataSection>
        </form>
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar newestOnTop closeOnClick rtl={false} draggable={true} pauseOnHover />
      </PurchaseLayout>
    )
  }
}
InvoiceData.contextType = AuthContext
InvoiceData.propTypes = {
  search: PropTypes.object,
}

export default withLocation(InvoiceData)
